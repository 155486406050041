<template>
  <div class="about">
    <h1>This is an about page</h1>
    <ad-sense-feed></ad-sense-feed>
  </div>
</template>

<script>
import AdSenseFeed from './../components/AdSenseFeed'
export default {
  components: {
    AdSenseFeed
  }
}
</script>
